<template>
    <particiInsuranceMobile v-if="isMobile">
    </particiInsuranceMobile>
	<div v-else class="interestrateHistory">
		<crumbs :item="title"></crumbs>
		<div class="wrap">
			<div class="time">{{ "最新信息披露日期:" + time }}</div>
			<p>红利实现率</p>
			<div class="tab">
				<div class="account">
					<span>产品名称：</span>
					<el-select 
						placement="bottom-start"
						:popper-append-to-body="false"
						v-model="productName"
						placeholder="请选择"
						@change="onChangeProductName"
					>
						<el-option
							placement="bottom-start"
							v-for="(item, index) in optionsProductName"
							:key="index"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</div>
				<div class="account">
					<span>分红年度：</span>
					<el-select
						:popper-append-to-body="false"
						v-model="yearsType"
						placeholder="请选择"
						@change="onChangeYearsType"
						@focus="onClickYearsType"
					>
						<el-option
							placement="bottom-start"
							v-for="(item, index) in optionsYears"
							:key="index"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</div>
				<div class="account">
					<div class="divFlex" v-show="tableData.dividendAchRate">
						<div class="textDiv">现金红利实现率:</div>
						<div class="valueDiv">{{percentageValue1}}<br/>{{percentageValues1}}</div>
						<!-- <div v-show="percentageValues1" class="valueDiv"></div> -->
					</div>
					<div  class="divFlex" v-show="tableData.incDividendRate">
						<div class="textDiv">增额红利实现率:</div>
						<div class="valueDiv">{{percentageValue2}}<br/>{{percentageValues2}}</div>
						<!-- <div v-show="percentageValues2" class="valueDiv"></div> -->
					</div>
					<div  class="divFlex" v-show="tableData.finalDividendRate">
						<div class="textDiv">终了红利实现率:</div>
						<div class="valueDiv">{{percentageValue3}}<br/>{{percentageValues3}}</div>
						<!-- <div v-show="percentageValues3" class="valueDiv">{{percentageValues3}}</div> -->
					</div>
					<!-- 增额红利实现率
					<el-input v-model="tableData.finalDividendRate">
					</el-input>
					终了红利实现率
					<el-input v-model="input1">
					</el-input> -->
				</div>
			</div>
			<div class="top_title">
				<p>说明:</p>
				<div>1.<p>分红年度2023适用于保单周年日在2024年1月1日（含）至2024年12月31日（含）期间满足分红条件的分红保险保单的红利发放。之前的分红年度以此类推。</p></div>
				<div>2.<p>红利实现率根据《关于印发一年期以上人身保险产品信息披露规则的通知》（银保监规[2022]24号）相关规定计算。采用现金红利分配方式的，披露现金红利实现率。采用增额红利分配方式的，披露增额红利实现率和终了红利实现率。各产品红利实现率计算方法：</p></div>
				<p style="margin-left:3%">&nbsp;&nbsp;（一）现金红利实现率</p> 
				<p style="margin-left:3%">&nbsp;&nbsp;实际派发的现金红利金额/红利利益演示的现金红利金额；</p>
				<p style="margin-left:3%">&nbsp;&nbsp;（二）增额红利实现率</p>
				<p style="margin-left:3%">&nbsp;&nbsp;实际派发的红利保额/红利利益演示的红利保额；</p>
				<p style="margin-left:3%">&nbsp;&nbsp;（三）终了红利实现率</p>
				<p style="margin-left:3%">&nbsp;&nbsp;实际派发的终了红利金额/红利利益演示的终了红利金额。</p>
				<p style="margin-left:3%">&nbsp;&nbsp;分红年度2023适用于保单周年日在2024年1月1日（含）至2024年12月31日（含）期间分红保险保单的红利发放。</p>
				<div>3.<p>红利实现率为每个分红产品所有有效保单合并计算的平均值，并不代表单个保险合同的实际红利分配水平，您所持有保险合同的实际红利分配金额以本公司发出的红利通知书为准。</p></div>
				<div>4.<p>分红保险的红利分配是不确定的，在某些年度红利可能为零。过去的红利实现率并不代表对未来的红利实现率的预期，未来的红利实现率可能低于或高于以上展示的历史红利实现率。</p></div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from "@/components/crumbs.vue";
    import particiInsuranceMobile from "./mobile/particiInsuranceMobile.vue";
	import common from '@/utils/common';
	// import Editor from "@tinymce/tinymce-vue";
	import {
		getParticiInsuranceTable,
		getParticiInsuranceOption,
	} from "@/api/newsnew/particiInsurance";
	import {getPublishDate } from "@/api/public";
	export default {
		name: "particiInsurance",
		data() {
			return {
                isMobile:false,
				title: [
					{
						title: "公开信息披露",
						link: "",
					},
					{
						title: "新型产品-分红险信息披露",
						link: "/#",
					}
				],
				count: 10,
				countType: "", //下拉框选中值
				optionsProductName: [], //下拉选项
				optionsYears: [], //下拉选项
				tableData: {}, //table数据
				loading: false, //加载
				html:null,
				yearsType:null,
				productName:"",
				time: "2022-09-20"
			};
		},
		components: {
			crumbs,
			particiInsuranceMobile
			// Editor
		},
		computed: {
				percentageValue1() {
					return this.tableData.dividendAchRate ? this.tableData.dividendAchRate  : '';
				},
				percentageValue2() {
					return this.tableData.incDividendRate ? this.tableData.incDividendRate: '';
				},
				percentageValue3() {
					return this.tableData.finalDividendRate ? this.tableData.finalDividendRate  : '';
				},
				percentageValues1() {
					return this.tableData.dividendAchRate2 ? this.tableData.dividendAchRate2 : '';
				},
				percentageValues2() {
					return this.tableData.incDividendRate2 ? this.tableData.incDividendRate2: '';
				},
				percentageValues3() {
					return this.tableData.finalDividendRate2 ? this.tableData.finalDividendRate2  : '';
				}
			},
		methods: {
			//下拉选项触发函数
			async onChangeProductName() {
				console.log(this.productName);
				//选择了年度就清空年份
				this.yearsType=null;
				this.tableData={};
				if(!this.yearsType){
					this.getParticiInsuranceOption();
					this.$message.warning("请继续选择分红年度");
					return 
				}
				// if(this.yearsType&&this.productName){
				// 	await this.getParticiInsuranceTable(this.productName,this.yearsType);
				// }
			},
			async onChangeYearsType() {
				console.log(this.yearsType);
				if(!this.productName){
					this.yearsType=null;
					this.$message.warning("请先选择产品");
					return 
				}
				this.tableData={};
				if(this.yearsType&&this.productName){
					await this.getParticiInsuranceTable(this.productName,this.yearsType);
				}
			},
			onClickYearsType(){
				if(!this.productName){
					this.yearsType=null;
					this.$message.warning("请先选择产品");
					return 
				}
			},
			//获取下拉选项数据
			async getParticiInsuranceOption() {
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { data },
				} = await  getParticiInsuranceOption({ timeStamp,sign ,orderByType:"asc",productName:this.productName});
				if(this.productName){
					this.optionsYears = data.dividendYears.map(element => ({
						value: element.dividendYear,
						label: element.dividendYear
					}))
				}else{
					this.optionsProductName = data.productNames.map(element => ({
						value: element.productName,
						label: element.productName
					}))
				}
			},
			//获取历史公布利率数据
			async getParticiInsuranceTable(productName,yearsType) {
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { data },
				} = await getParticiInsuranceTable({ timeStamp:timeStamp, sign:sign, productName:productName,dividendYear:yearsType });
				console.log('data=================',data)
				if(data&&data.length>0){
					this.tableData=data && data.length>0? data[0]:null;
				}
			},
			//截取时间
			getStr(string, str) {
				if(string&&str){
					return string.split(str)[0];
				}else{
					return null;
				}
			},
			async getDisclosureDate(){
				const [timeStamp, sign] = this.$getSign();
				//获取披露日期
				const res =  await getPublishDate({timeStamp,sign,contentType:this.$route.params.id});
				this.time = this.getStr(res.data.data, "T")
			}
		},
		async created() {
			this.countType=localStorage.getItem('interestrateHistoryCovercode');
			// this.getParticiInsuranceTable(this.countType);
            this.isMobile = common.isMobile();
			var isPad = common.isPad();
			console.log("this.isMobile================",this.isMobile)
			if(isPad){
				this.isMobile = false
			}
			this.getDisclosureDate();//获取披露日期
            if (!this.isMobile) {
                await this.getParticiInsuranceOption(); //获取选项数据
            }
		},
	};
</script>

<style lang="less" scoped>
.mytable-scrollbar ::-webkit-scrollbar {
	width: 5px ;
  } 
	.interestrateHistory {
		.wrap {
			width: 100%;

			> p {
				text-align: center;
				font-size: 4rem;
				margin: 4rem 0;
				color: #003781;
			}

			> .tab {
				//width: 60%;
				width: 148rem;
				margin: auto;
				padding: 0 5rem 5rem;
				// height: 52rem;
				margin-bottom: 6rem;

				//账户
				.account {
					.divFlex{
						display: flex;
						margin:  2% 0;
						.textDiv{
							width: 11.1%;
							margin-top: 7px;
							font-weight: 700;
						}
						.valueDiv{
							//height: 35px;
							text-align: left;
							width: 49.9rem;
							border: 1px solid #dcdfe6;
							border-radius: 5px;
							line-height: 35px;
							padding-left: 1%;
						}
						/deep/ .el-input__inner{
							margin-left: 3.5px;
							height: 4.5rem !important;
							background-color: #FFF;
							color: #1a1a1a;
						}
					}
						/deep/ .el-select{
						.el-select-dropdown{
							left: 11% !important;
						}
					}
					padding: 20px 10px 20px 40px;
					font-size: 1.8rem !important;
				
					//select
					/deep/ .el-input__inner {
						width: 50rem;
						height: 3.5rem;
						line-height: 3.5rem;
						margin-left: 11%;
						font-size: 1.8rem;
					}

					//select-item
					/deep/ .el-input__suffix {
						.el-input__suffix-inner {
							.el-input__icon {
								line-height: 3.5rem;
								position: relative;
								right: -40px;
							}
						}
					}
					span{
						font-weight: 700 !important;
					}
				}
				.tableData{
					font-size: 14.5px;
					font-weight:600 ;
					/deep/ .el-input{
						width: 36.1%;
						margin: 3%;
						margin: 11px 0;
						position: relative;
						top: -20px;
						left: 10px;
					}
					/deep/ .el-input__inner{
						background-color: #FFF !important;
						color: #1a1a1a;
					}
				}
				.pricingDay {
					padding: 10px 10px 10px 40px;
					font-size: 1.8rem;
				}

				.productName {
					display: flex;
					align-items: center;
					padding: 0 2rem;

					> div {
						margin: 1rem 3rem;
					}
				}

				> .table {
					width: 90%;
					margin: auto;
				}
			}
			.top_title{
				font-size:1.75rem !important;
				font-family: 宋体;
				margin: 3% 13%;
				line-height: 1.5;
				> p{
					line-height: 1.7;
				}
				> div{
					
					display: flex;
					> p{
						margin-left: 2%;
					}
				}
			}
			.time{
				text-align: right;
				margin-right: 13%;
				margin-top: 2%;
			}
		}

		.history_title {
			width: 900px;
			margin: 0 auto 0rem;

			.type_sec {
				background: #edeff5;
				padding-bottom: 10px;
				padding: 10px 32px 10px 50px;
				font-size: 1.8rem;

				/deep/ .el-input__inner {
					width: 40rem;
					height: 3.5rem;
					line-height: 3.5rem;
				}

				/deep/ .el-input__suffix {
					.el-input__suffix-inner {
						.el-input__icon {
							line-height: 3.5rem;
						}
					}
				}
			}

			.table_style {
				border-collapse: collapse;
				width: 100%;

				.title {
					background: #edeff5;

					.title_color {
						color: #003781;
					}
				}

				tr {
					height: 4rem;
					line-height: 4rem;

					td {
						font-family: 方正正中黑简体;
						font-size: 1.8rem;
						width: 150px;
						text-align: center;
						font-weight: 400;
					}
				}
			}
		}

		.history_Info {
			overflow-y: scroll;
			padding-bottom: 50px;
			width: 900px;
			margin: 0 auto 0rem;
			height: 25vw;
			/* border: 1px solid; */
			display: flex;
			justify-content: center;

			.table_style {
				border-collapse: collapse;
				width: 100%;

				.title {
					background: #edeff5;

					.title_color {
						color: #003781;
					}
				}

				// tbody {
				//   border-bottom: 1px solid #3e3e3e;
				// }
				tr {
					height: 3.5rem;
					line-height: 3.5rem;

					td {
						font-family: 方正正中黑简体;
						color: #3e3e3e;
						font-size: 1.8rem;
						width: 150px;
						text-align: center;
						font-weight: 400;
						border-bottom: unset;
					}
				}

				.loading_style {
					margin-top: 15px;
					font-size: 2rem;
				}
			}
		}
	} 
	
</style>
